import React from 'react'
import Jibjib from './Jibjib'
function Header() {
    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
            <Jibjib />
        </div>
    )
}

export default Header
