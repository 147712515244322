import React, { useEffect, useState } from 'react'
import AnimatedNumber from 'animated-number-react'
import {
    useWeb3ModalAccount,
    useWeb3Modal,
    useWeb3ModalProvider,
} from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits, parseEther } from 'ethers'
import Backdrop from '@mui/material/Backdrop'
import { BiLoaderAlt } from 'react-icons/bi'
import { RiCloseCircleFill } from 'react-icons/ri'

const JIBJIB = process.env.REACT_APP_UMMO
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
const IERC20 = require('../abi/IERC20')
const STAKEABI = require('../abi/STAKEABI')

function Loader() {
    return (
        <button
            type="button"
            className="border-4 flex flex-row items-center justify-center border-[#ffaa00] text-white text-3xl font-bold w-full mt-2 p-1 cursor-default  bg-[#ffd476] rounded-xl transition-all"
        >
            <BiLoaderAlt className="animate-spin mr-2" />
            <span className="animate-pulse">Processing... </span>
        </button>
    )
}

function Exchange() {
    const [jbc, setJbc] = useState(350)
    const [jibjib, setJibjib] = useState(0)
    const [coin, setCoin] = useState(JIBJIB)
    const [isProcess, setIsProcess] = useState(false)
    const [focusedInput, setFocus] = useState(null)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [pop, setPop] = useState(false)
    const [state, setState] = useState(0)
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()
    const { open, close } = useWeb3Modal()

    let total = 0
    const rate = 1 * 10 ** 7

    const handleClose = () => {
        setPop(false)
    }
    const handleOpen = () => {
        setPop(true)
    }

    useEffect(() => {
        //console.clear()
        if (coin == JIBJIB) {
            setJbc(jibjib)
        } else {
            setJbc(jibjib * rate)
        }

        return () => {}
    }, [jbc, jibjib, focusedInput, coin])

    async function getBalance() {
        if (!isConnected) throw Error('User disconnected')

        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
        // The Contract object
        const USDTContract = new Contract(JIBJIB, IERC20, signer)
        const USDTBalance = await USDTContract.balanceOf(address)

        console.log(formatUnits(USDTBalance, 18))
    }

    async function mint(_jibjib) {
        const etherValue = parseEther(_jibjib.toString())
        if (!isConnected) throw Error('User disconnected')
        console.log('_jibjib:', etherValue)
        const ethersProvider = new BrowserProvider(walletProvider)

        try {
            const signer = await ethersProvider.getSigner()
            const JJBContract = new Contract(JIBJIB, IERC20, signer)
            const tx = await JJBContract.mint({
                value: etherValue,
            })

            setInfo('Waiting for a block confirmation')

            const receipt = await tx
                .wait(1)
                .then((x) => {
                    console.log('txHash:', x.transactionHash)
                    setPop(true)
                    setState(2)
                    setInfo('Transaction Complete!')
                    setIsProcess(false)
                })
                .catch((error) => {
                    console.log('error code:', error.code)
                    console.error(error)
                    setIsProcess(false)
                    handleClose()
                })
            console.log('tx:', tx)
        } catch (error) {
            setIsProcess(false)
            handleClose()
            console.error(error)
        }
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center select-none">
            <div className="mt-10 border-4 border-black bg-svg2 md:w-[512px] w-full p-3 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-3xl  bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm -mt-6 my-1">
                    <br />
                </span>
                <div className="rounded-t-2xl w-full bg-white hover:bg-yellow-100 z-10 transition-all drop-shadow-md">
                    <div className=" w-full flex justify-between my-0">
                        <span className="text-black text-xl p-3">
                            You spend
                        </span>
                        <span className="text-black text-xs p-3 flex flex-row"></span>
                    </div>
                    <div className=" w-full flex justify-between -mt-5">
                        <div className="w-full flex justify-between">
                            <span className="p-3 text-5xl font-bold text-black flex flex-row ">
                                <div className="aspect-square">
                                    {' '}
                                    <img
                                        width="40"
                                        className=" aspect-square mr-2"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/img/bnb.svg'
                                        }
                                    />
                                </div>
                                <div>BNB</div>
                            </span>
                        </div>
                        <input
                            placeholder="BNB"
                            type="number"
                            min="0.01"
                            step="0.01"
                            id="jbc"
                            value={jibjib}
                            autoComplete="off"
                            onFocus={() => setFocus('usd')}
                            onChange={(e) => {
                                // Allows numbers and a single decimal point
                                const value = e.target.value
                                if (
                                    value === '' ||
                                    (!isNaN(value) && value >= 0.01)
                                ) {
                                    setJibjib(value)
                                }
                            }}
                            className="border-2 text-right my-2 w-full tracking-widest active:outline-none rounded-sm pr-10 outline-non bg-transparent text-black border-none text-5xl font-bold focus:outline-none"
                        />
                        <RiCloseCircleFill
                            className="absolute right-10 text-[#ffaa00] hover:text-red-500 hover:cursor-pointer"
                            fontSize={18}
                            onClick={() => {
                                setJibjib(0)
                            }}
                        />
                    </div>
                </div>

                <div className="rounded-b-2xl  w-full bg-white hover:bg-yellow-100 z-10 transition-all drop-shadow-md">
                    <div className=" w-full flex justify-between my-0 ">
                        <span className="text-black text-xl p-3">
                            You'll got
                        </span>{' '}
                        <span className="p-3 text-xl font-bold text-black md:hidden flex flex-row ">
                            <div className="aspect-square">
                                <img
                                    width="24"
                                    className=" mr-2  "
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/coins/ummocoin.png'
                                    }
                                />
                            </div>
                            UMMO
                        </span>
                    </div>

                    <div className="w-full flex justify-between -mt-5">
                        <div className="w-full flex justify-between items-center">
                            <span className="p-3 text-5xl font-bold text-black md:flex flex-row hidden">
                                <div className="aspect-square">
                                    <img
                                        width="40"
                                        className=" mr-2  "
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/coins/ummocoin.png'
                                        }
                                    />
                                </div>
                                UMMO
                            </span>
                        </div>
                        <AnimatedNumber
                            className="mt-3 px-10 text-5xl text-black flex flex-row"
                            value={jbc * rate}
                            formatValue={formatValue}
                            duration="500"
                        />
                    </div>
                </div>
                {isProcess ? (
                    <Loader />
                ) : isConnected ? (
                    <button
                        type="button"
                        onClick={() => {
                            if (jibjib < 0.01) {
                                setError('0.01 BNB Minimum Purchase required')
                            } else if (chainId != 56) {
                                setError(
                                    'Wrong Network! Please connect to JIBCHAIN'
                                )
                            } else {
                                setInfo(
                                    'Waiting for your confirmation on Metamask'
                                )
                                mint(jibjib)
                                handleOpen()
                                setState(1)
                                setIsProcess(true)
                                setError('')
                            }
                        }}
                        className="border-4 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold w-full mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                    >
                        Mint!
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            open()
                        }}
                        className="border-4 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold w-full mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                    >
                        Connect Wallet
                    </button>
                )}
                <span className="text-red-600 font-bold text-xl mt-1 -mb-2">
                    {error}
                </span>
            </div>
            <div className="w-full md:w-[400px]  flex justify-between m-1 drop-shadow-md px-10">
                <span className="text-white text-xl">Rate BNB</span>
                <span className="text-white text-xl">
                    {formatValue(rate)} UMMO
                </span>
            </div>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={pop}
                className="flex flex-col"
            >
                <div className="bg-white  max-h-[70vh] flex flex-col justify-center items-center m-6 text-center text-gray-900 text-lg p-3 rounded-lg md:w-[512px] w-full display-linebreak">
                    <span className="absolute text-black/5">#{state}</span>
                    {state == 1 ? (
                        <>
                            <div className="mt-5 text-3xl font-bold">
                                {info}
                            </div>

                            <img
                                width="98"
                                className=""
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/Infinity-loading.svg'
                                }
                            />
                        </>
                    ) : null}
                    {state == 2 ? (
                        <>
                            <div className="mt-5 text-3xl font-bold">
                                {info}
                            </div>
                            <br />
                            <button
                                type="button"
                                onClick={() => {
                                    handleClose()
                                    setState(0)
                                    setIsProcess(false)
                                }}
                                className="border-4 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold w-full mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                            >
                                Ok
                            </button>
                        </>
                    ) : null}
                </div>
            </Backdrop>
        </div>
    )
}

export default Exchange
