import React, { useState } from 'react'
import { useWeb3ModalProvider } from '@web3modal/ethers/react'
import { FaLine, FaTelegram, FaFacebook } from 'react-icons/fa6'
import Backdrop from '@mui/material/Backdrop'

const UMMO = process.env.REACT_APP_UMMO
console.log('UMMO:', UMMO)
function Footer() {
    const [state, setState] = useState(0)
    const [pop, setPop] = useState(false)
    const { walletProvider } = useWeb3ModalProvider()

    const handleClose = () => {
        setPop(false)
    }
    const handleOpen = () => {
        setPop(true)
    }

    async function addTokenFunction(_coin, n = 1) {
        let cimg
        let symbol
        if (n == 1) {
            cimg = process.env.PUBLIC_URL + '/coins/ummocoin.png'
            symbol = 'UMMO'
        }
        console.log('address', _coin)
        console.log('symbol', symbol)
        console.log('decimals', 18)
        console.log('image', cimg)
        try {
            const wasAdded = await walletProvider.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: _coin,
                        symbol: symbol,
                        decimals: 18,
                        image: cimg,
                    },
                },
            })

            if (wasAdded) {
                console.log('Thanks for your interest!')
            } else {
                console.log('PTC has not been added')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center select-none">
            <div className="w-full md:w-[400px]  flex flex-col justify-center drop-shadow-md px-10">
                <span className="text-white text-xl ">$UMMO Contract</span>
                <span className="text-white text-xl hover:text-[#ffd476] hover:scale-110 transition-all overflow-hidden whitespace-pre-wrap">
                    <a
                        href={`https://bscscan.com/address/${UMMO}`}
                        target="_blank"
                    >
                        {UMMO}
                    </a>
                </span>
                <span
                    onClick={() => {
                        addTokenFunction(UMMO)
                    }}
                    className="flex flex-row justify-center items-center text-white text-xl hover:text-[#ffd476] cursor-pointer  hover:scale-110 transition-all"
                >
                    <img
                        width="24"
                        className=" aspect-square m-2 hover:scale-105"
                        src={process.env.PUBLIC_URL + '/img/metamask.png'}
                    />
                    Add{' '}
                    <img
                        width="24"
                        className="aspect-square m-2 hover:scale-105"
                        src={process.env.PUBLIC_URL + '/coins/ummocoin.png'}
                    />
                    $UMMO{' '}
                </span>
            </div>
            <br />
            <div className="w-full md:w-[400px]  flex flex-col justify-center drop-shadow-md px-10">
                <span
                    onClick={() => {
                        handleOpen()
                        setState(9)
                    }}
                    className="text-white text-2xl mt-1 hover:text-[#ffd476] cursor-pointer  hover:scale-110 transition-all"
                >
                    About Us
                </span>
            </div>
            <div className="flex flex-row justify-center p-2 my-2 text-white">
                <a
                    href="https://www.facebook.com/groups/jibchain"
                    target="_blank"
                >
                    <FaFacebook
                        size={28}
                        className="hover:text-[#ffd476] cursor-pointer mx-2 hover:scale-110 transition-all"
                    />
                </a>

                <a href="https://t.me/+KLpMckwYu7c1ZThl" target="_blank">
                    <FaTelegram
                        size={28}
                        className="hover:text-[#ffd476] cursor-pointer mx-2 hover:scale-110 transition-all"
                    />
                </a>
                <a href="https://line.me/R/ti/g/prHeTOkZIu" target="_blank">
                    <FaLine
                        size={28}
                        className="hover:text-[#ffd476] cursor-pointer mx-2 hover:scale-110 transition-all"
                    />
                </a>
            </div>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={pop}
                className="flex flex-col"
            >
                <div className="bg-white  max-h-[70vh] flex flex-col justify-center items-center m-6 text-center text-gray-900 text-lg p-3 rounded-lg md:w-[512px] w-full display-linebreak">
                    <span className="absolute text-black/5">#{state}</span>

                    {state == 9 ? (
                        <>
                            <div className="mt-5 p-3 font-normal text-start text-base font-sans  overflow-scroll overflow-x-auto">
                                <p className="font-bold">
                                    UMMO เหรียญ meme coin UMMO meme coin
                                </p>
                                <p>
                                    <br />
                                    Projectได้รับแรงบันดาลจากเหรียญ Dogecoin และ
                                    meme ที่มีในทุกๆ chain ซึ่งใน JBC chain
                                    ยังไม่มีเหรียญ meme
                                    ที่จะเป็นตัวแทนของเหรียญที่สร้างความน่ารัก
                                    ความสนุกสนานให้กับผู้คน UMMO มีน้องลูกไก่
                                    เป็นสัญลักษณ์ของเหรียญน้องไก่ UMMO
                                    ที่จะส่งเสียงร้องจิ๊บ จิ๊บ
                                    และพร้อมที่จะเติบโตจากการเฝ้าถนอมเลี้ยงดูจากทุกคน
                                    ใครยังไม่มีน้องไก่ลูกจิ๊บสามารถซื้อไปเลี้ยงกันได้นะครับ
                                    ด้วยอัตราแลกเปลี่ยน 1 BNB : 10,000,000 UMMO
                                    ผู้สร้างจะผลักดัน UMMO
                                    ให้เป็นเหรียญขวัญใจของชุมชน Cryptocurrency
                                    ทั่วโลก พร้อมที่จะพัฒนาต่อไปในอนาคต{' '}
                                </p>
                                <br />
                                <p>
                                    *จุดประสงค์ใช้เป็นเหรียญสำหรับส่งมอบของขวัญแทนคำขอบคุณ
                                    เป็นเหรียญที่ขับเคลื่อนชุมชน meme coin ใน
                                    JBC chain{' '}
                                </p>
                                <br />
                                <p>
                                    วันเปิดตัวเหรียญ UMMO : 25 / 12 / 2023 เวลา
                                    : 9.19 น. ตามเวลา ประเทศไทย{' '}
                                </p>
                                <br />
                                <p className="font-bold">Tokennomic</p>
                                <p>
                                    เหรียญJIBJIB จะถูกแบล็คมูลค่าด้วยเหรียญ JBC
                                    ซึ่งเป็นเหรียญหลักของ JBC chain Total supply
                                    3,000,000,000,000 UMMO{' '}
                                </p>
                                <p>1.Pre-sale 1ล้านล้าน</p>
                                <p>2.ทุนสำรอง 1 ล้านล้าน </p>
                                <p>3.แลกหน้าเวปไซต์ 1 ล้านล้าน</p>
                                <br />
                                <p className="font-bold">Event</p>
                                <p>
                                    {' '}
                                    ✓ก่อนวัน LP เหรียญjbc/jibjib
                                    จะเผาเหรียญที่เหลือจากทั้ง 3 ข้อ ทั้งหมด
                                    รายได้ที่เป็น JBC จะนำไป LP ต่อไป
                                    ตอนนั้นมูลค่าของ UMMO $
                                    จะมีมูลค่าตามกลไกตลาดและมูลค่าของเหรียญJBC
                                </p>
                                <br />
                                <p>
                                    **จะมีกิจกรรมและโปรดักส์อื่นๆตามมาในอนาคตครับ
                                    มาสร้าง community UMMO
                                    ให้มีความสุขสนุกสนานและรอยยิ้มร่วมกันนะครับ🥰
                                </p>
                                <br />
                                <span
                                    onClick={() => {
                                        addTokenFunction(UMMO)
                                    }}
                                    className="w-full px-2 flex flex-row items-center justify-center border-black text-white hover:text-[#ffaa00] hover:scale-105 text-base font-bold bg-[#ffaa00] rounded-md cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                                >
                                    <img
                                        width="24"
                                        className="aspect-square m-2 hover:scale-105"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/img/metamask.png'
                                        }
                                    />
                                    เพิ่มเหรียญ{' '}
                                    <img
                                        width="24"
                                        className="aspect-square m-2 hover:scale-105"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/coins/ummocoin.png'
                                        }
                                    />
                                    $UMMO{' '}
                                </span>

                                <div className="flex flex-row justify-center p-2 mt-3">
                                    <a
                                        href="https://www.facebook.com/groups/jibchain"
                                        target="_blank"
                                    >
                                        <FaFacebook
                                            size={32}
                                            className="hover:text-[#ffaa00] cursor-pointer mx-2 hover:scale-110 transition-all"
                                        />
                                    </a>

                                    <a
                                        href="https://t.me/+KLpMckwYu7c1ZThl"
                                        target="_blank"
                                    >
                                        <FaTelegram
                                            size={32}
                                            className="hover:text-[#ffaa00] cursor-pointer mx-2 hover:scale-110 transition-all"
                                        />
                                    </a>
                                    <a
                                        href="https://line.me/R/ti/g/prHeTOkZIu"
                                        target="_blank"
                                    >
                                        <FaLine
                                            size={32}
                                            className="hover:text-[#ffaa00] cursor-pointer mx-2 hover:scale-110 transition-all"
                                        />
                                    </a>
                                </div>
                            </div>
                            <br />
                            <button
                                type="button"
                                onClick={() => {
                                    handleClose()
                                    setState(0)
                                }}
                                className="border-0 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold w-full mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                            >
                                Ok
                            </button>
                        </>
                    ) : null}
                </div>
            </Backdrop>
        </div>
    )
}

export default Footer
